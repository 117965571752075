<template>
  <!-- modal -->
  <b-modal
    :id="'modal-quiz-create-'+groupQuiz.id"
    :ref="'modal-quiz-create-'+groupQuiz.id"
    :title="'Crea un nuovo quiz'"
    ok-title="Salva"
    cancel-title="Chiudi"
    cancel-variant="outline-secondary"
    size="xl"
    @show="resetModal"
    @hidden="deleteProp"
    @ok="handleOk"
  >
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="transparent"
      :opacity="0.85"
      blur="2px"
      rounded="sm"
    >
      <validation-observer ref="createQuizForm">
        <form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form"
          @submit.stop.prevent="validationForm"
        >

          <b-row>
            <b-col
              lg="10"
              md="10"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  label="Nome"
                  label-for="name"
                  invalid-feedback="Obbligatorio"
                >
                  <b-form-input
                    id="name"
                    v-model="quizData.name"
                    :state="getValidationState(validationContext)"
                    size="lg"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col class="text-right">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mt-2 mb-2"
                @click="repeateAgain"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Aggiungi domanda</span>
              </b-button>
            </b-col>
          </b-row>

          <!-- Row Loop -->
          <b-row
            v-for="(question, index) in quizData.quiz"
            :id="question.id"
            :key="question.id"
            ref="row"
            class="ml-1 mt-3"
          >
            <!-- Item Name -->
            <b-col>
              <b-row>
                <b-col
                  lg="10"
                  md="9"
                  sm="12"
                  cols="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="question"
                    rules="required"
                  >
                    <b-form-group
                      label="Domanda"
                      label-for="question-input"
                      invalid-feedback="Obbligatorio"
                    >
                      <b-form-input
                        v-if="quizData.quiz[index]"
                        :id="'question-input-'+index"
                        v-model="quizData.quiz[index].question"
                        :state="getValidationState(validationContext)"
                        size="lg"
                        required
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Remove Button -->
                <b-col
                  v-if="quizData.quiz.length > 1"
                  lg="2"
                  md="3"
                  class="mb-50"
                >
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    size="sm"
                    @click="removeItem(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                    <span>Cancella</span>
                  </b-button>
                </b-col>

                <b-col
                  lg="12"
                  md="12"
                  sm="12"
                  cols="12"
                  class="ml-2"
                >
                  <quiz-answers-create :answers="question.answers" />
                </b-col>
              </b-row>
              <b-row>
                <b-col v-if="quizData.quiz.length > 1">
                  <hr>
                </b-col>
              </b-row>
            </b-col>

          </b-row>

          <div class="d-flex flex-row-reverse">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mt-2 mb-2"
              @click="repeateAgain"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Aggiungi domanda</span>
            </b-button>
          </div>

        </form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BFormInput, BModal, VBModal, BRow, BCol, BButton, BOverlay,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, alphaNum, integer, digits,
} from '@validations'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import QuizAnswersCreate from '@/views/quiz/quiz-create/QuizAnswersCreate.vue'

export default {
  components: {
    QuizAnswersCreate,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BCol,
    BButton,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    groupQuiz: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      integer,
      digits,
      nextTodoId: 0,
    }
  },
  watch: {},
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {

    validationForm() {
      this.$refs.createQuizForm.validate()
        .then(success => {
          if (success) {
            this.handleSubmit()
          }
        })
    },
    resetModal() {
      // this.resetquizData()
    },
    deleteProp() {
      this.$emit('reset-props')
      // this.resetquizData()
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.validationForm()
    },
    handleSubmit() {
      this.onSubmit()

      // Hide the modal manually
      // this.$nextTick(() => {
      //   this.$refs['modal-create-quiz'].toggle('#toggle-btn')
      // })
    },
    repeateAgain() {
      this.quizData.quiz.push({
        id: this.nextTodoId += 1,
        question: null,
        answers: [{
          id: 0,
          text: null,
          correct: false,
        }],
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.quizData.quiz.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup(props, {
    emit,
    refs,
  }) {
    // Use toast
    const toast = useToast()

    const show = ref(false)

    const blankQuizData = {
      name: null,
      group_quiz_id: props.groupQuiz.id,
      status: 'published',
      quiz: [{
        id: 0,
        question: null,
        answers: [{
          id: 0,
          text: null,
          correct: false,
        }],
      }],
    }

    const quizData = ref(JSON.parse(JSON.stringify(blankQuizData)))
    const resetquizData = () => {
      quizData.value = JSON.parse(JSON.stringify(blankQuizData))
    }

    const onSubmit = () => {
      show.value = !show.value
      // console.log(sendData.upline_code, sendData.upline_dir)
      // if (sendData.upline_code != null) {
      //   sendData.upline_dir = null
      // }
      store.dispatch('quizzes/addQuiz', quizData.value)
        .then(() => {
          emit('refetch-data')
          refs[`modal-quiz-create-${props.groupQuiz.id}`].toggle('#toggle-btn')
          resetquizData()
          show.value = false
        })
        .catch(error => {
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
          show.value = false
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetquizData)

    return {
      show,

      quizData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      resetquizData,
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
