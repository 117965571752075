<template>
  <div v-if="Object.values(getListGroupQuiz()).length">
    <b-row>
      <b-col
        v-for="groupQuiz in getListGroupQuiz()"
        :key="groupQuiz.id"
        lg="4"
        md="4"
        sm="6"
        cols="12"
      >
        <b-card
          align="center"
          class="card-transaction"
          border-variant="danger"
          no-body
        >
          <b-card-header>
            <b-card-title>{{ groupQuiz.name }}</b-card-title>
            <b-button
              variant="primary"
              size="sm"
              @click="$bvModal.show('modal-quiz-create-'+groupQuiz.id)"
            >
              <feather-icon
                icon="PlusIcon"
                class="cursor-pointer"
              />
            </b-button>
          </b-card-header>

          <b-card-body>
            <div
              v-for="quiz in groupQuiz.quizzes"
              :key="quiz.id"
              class="transaction-item"
            >
              <b-media no-body>
                <b-media-aside>
                  <b-button
                    v-if="quiz.deletable"
                    variant="flat-danger"
                    size="sm"
                    @click="swalConfirmDeleteQuiz(quiz.id)"
                  >
                    <feather-icon
                      size="18"
                      icon="XIcon"
                    />
                  </b-button>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">
                    {{ quiz.name }}
                  </h6>
                  <small>
                    Domande: {{ quiz.quiz.length }}
                  </small>
                </b-media-body>
              </b-media>
              <div>
                <b-button
                  variant="primary"
                  size="sm"
                  class="btn-block"
                  @click="$bvModal.show('modal-quiz-edit-'+quiz.id)"
                >
                  <span>Vedi</span>
                </b-button>
              </div>
              <quiz-modal-edit
                :quiz-edit-data="quiz"
                @refetch-data="fetchGroupQuiz"
              />
            </div>
            <!-- Product Actions -->
            <div class="item-options text-center mt-1">
              <b-row>
                <!--                <b-col-->
                <!--                  cols="12"-->
                <!--                  class="mb-1"-->
                <!--                >-->
                <!--                  <b-button-->
                <!--                    variant="primary"-->
                <!--                    size="sm"-->
                <!--                    class="btn-block"-->
                <!--                    @click="$bvModal.show('modal-quiz-create')"-->
                <!--                  >-->
                <!--                    <span>Aggiungi Quiz</span>-->
                <!--                  </b-button>-->
                <!--                </b-col>-->
                <b-col
                  lg="6"
                  md="12"
                >
                  <b-button
                    variant="primary"
                    size="sm"
                    class="btn-block"
                    @click="$bvModal.show('modal-group-quiz-edit-'+groupQuiz.id)"
                  >
                    <span>Modifica</span>
                  </b-button>
                </b-col>
                <b-col
                  v-if="groupQuiz.deletable"
                  lg="6"
                  md="12"
                >
                  <b-button
                    variant="outline-danger"
                    size="sm"
                    class="btn-block"
                    @click="swalConfirmDeleteGroupQuiz(groupQuiz.id)"
                  >
                    <span>Elimina</span>
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-card>

        <quiz-modal
          :group-quiz="groupQuiz"
          @refetch-data="fetchGroupQuiz"
        />
        <group-quiz-modal-edit
          :group-quiz="groupQuiz"
          @refetch-data="fetchGroupQuiz"
        />
      </b-col>
    </b-row>

  </div>
</template>

<script>

import {
  VBToggle, BCard, BRow, BCol, BCardBody, BButton, BMedia, BMediaAside, BMediaBody, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useQuizList from '@/views/quiz/useQuizList'
import QuizModalEdit from '@/views/quiz/quiz-edit/QuizModalEdit.vue'
import store from '@/store'
import QuizModal from '@/views/quiz/quiz-create/QuizModal.vue'
import GroupQuizModalEdit from '@/views/quiz/quiz-edit/GroupQuizModalEdit.vue'

export default {
  name: 'QuizList',
  components: {
    GroupQuizModalEdit,
    QuizModal,
    QuizModalEdit,
    BCard,
    BRow,
    BCol,
    BCardBody,
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardTitle,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  methods: {
    swalConfirmDeleteQuiz(id) {
      this.$swal({
        title: 'Sei sicura/o?',
        text: 'Non potrai annullare questa operazione',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si cancella!',
        cancelButtonText: 'Chiudi',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('quizzes/deleteQuiz', id)
              .then(() => {
                this.refetchData()
                this.fetchGroupQuiz()
                this.$swal({
                  icon: 'success',
                  title: 'Cancellato!',
                  text: 'Quiz cancellato',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Annullata',
              text: 'Cancellazione annullata',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          return result.value
        })
    },
    swalConfirmDeleteGroupQuiz(id) {
      this.$swal({
        title: 'Sei sicura/o?',
        text: 'Non potrai annullare questa operazione',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si cancella!',
        cancelButtonText: 'Chiudi',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('quizzes/deleteGroupQuiz', id)
              .then(() => {
                this.refetchData()
                this.fetchGroupQuiz()
                this.$swal({
                  icon: 'success',
                  title: 'Cancellato!',
                  text: 'Gruppo cancellato',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Annullata',
              text: 'Cancellazione annullata',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          return result.value
        })
    },
  },
  setup() {
    const {
      // Options
      //
      fetchQuiz,
      fetchGroupQuiz,
      refetchQuiz,
      getListQuiz,
      getListGroupQuiz,
      perPage,
      currentPage,
      totalQuizzes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refQuizListTable,
      refetchData,

      // Extra Filters
      //
      statusResolver,
      statusNameResolver,
    } = useQuizList()
    fetchGroupQuiz()
    fetchQuiz()

    return {
      // Options

      //
      fetchQuiz,
      fetchGroupQuiz,
      refetchQuiz,
      getListQuiz,
      getListGroupQuiz,
      perPage,
      currentPage,
      totalQuizzes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refQuizListTable,
      refetchData,

      // Extra Filters
      //
      statusResolver,
      statusNameResolver,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
