<template>

  <b-overlay
    id="overlay-background"
    :show="show"
    variant="transparent"
    :opacity="0.85"
    blur="2px"
    rounded="sm"
  >
    <!-- Row Loop -->
    <b-row
      v-for="(answer, index) in answers"
      :id="answer.id"
      :key="index"
      ref="row"
    >
      <!-- Item Name -->
      <b-col>
        <b-row>
          <b-col
            lg="8"
            md="8"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="answer"
              rules="required"
            >
              <b-form-group
                label="Risposta"
                label-for="answer-input"
                invalid-feedback="Obbligatorio"
              >
                <b-form-input
                  v-if="answers[index]"
                  :id="'answer-input-'+index"
                  v-model="answers[index].text"
                  :state="getValidationState(validationContext)"
                  size="sm"
                  required
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            lg="2"
            md="2"
            sm="6"
            cols="6"
            class="mb-50"
          >
            <b-form-checkbox
              v-model="answers[index].correct"
              :value="true"
              class="mt-md-2"
            >
              Corretta
            </b-form-checkbox>
          </b-col>

          <b-col
            lg="2"
            md="2"
            sm="6"
            cols="6"
            class="mb-50"
          >
            <b-button-group>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                size="sm"
                class="mt-md-2"
                @click="repeateAgain"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
              </b-button>
              <b-button
                v-if="answers.length > 1"
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-md-2"
                size="sm"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
              </b-button>
            </b-button-group>
          </b-col>

        </b-row>
      </b-col>

    </b-row>
  </b-overlay>
</template>

<script>
import {
  BFormGroup, BFormInput, VBModal, BRow, BCol, BButton, BOverlay, BButtonGroup, BFormCheckbox,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider } from 'vee-validate'

import {
  required, alphaNum, integer, digits,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BButtonGroup,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    answers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      integer,
      digits,
      nextTodoId: Object.values(this.answers).length - 1,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {

    validationForm() {
      this.$refs.createAnswersForm.validate()
        .then(success => {
          if (success) {
            this.handleSubmit()
          }
        })
    },
    resetModal() {
      // this.resetanswersData()
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.validationForm()
    },
    handleSubmit() {
      this.onSubmit()

      // Hide the modal manually
      // this.$nextTick(() => {
      //   this.$refs['modal-create-answers'].toggle('#toggle-btn')
      // })
    },
    repeateAgain() {
      this.answers.push({
        id: this.nextTodoId += 1,
        text: null,
        correct: false,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.answers.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {
    // Use toast

    const show = ref(false)

    const blankanswersData = [{
      id: 0,
      text: null,
      correct: false,
    }]

    const answersData = ref(JSON.parse(JSON.stringify(blankanswersData)))
    const resetanswersData = () => {
      answersData.value = JSON.parse(JSON.stringify(blankanswersData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetanswersData)

    return {
      show,
      answersData,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss" scoped>
    .repeater-form {
        overflow: hidden;
        transition: .35s height;
    }
</style>
